<template>
  <NuxtLayout name="sticky">
    <template v-if="!noNavbar" #header>
      <nav class="nav flex items-center px-md h-full">
        <LcButton
          class="button-back"
          :icon="navIcon"
          size="small"
          :to="backRoute"
          variant="tertiary"
          @click="
            !backRoute &&
              navigateBack({
                previousRoute: previousRoute,
              })
          "
        />

        <h1
          v-if="props.title"
          class="headline w-full text-h3 text-center"
          v-text="props.title"
        />
      </nav>
    </template>

    <template #default>
      <slot />
    </template>

    <template #after>
      <TheFooter />
    </template>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import { LcButton } from '@lottocom/frontend-components'

import type { RouteLocationRaw } from '#vue-router'

export type OverlayLayoutProps = {
  backRoute?: RouteLocationRaw
  navIcon?: 'arrow-left' | 'close'
  noNavbar?: boolean
  previousRoute?: RouteLocationRaw
  title?: string
}

const props = withDefaults(defineProps<OverlayLayoutProps>(), {
  navIcon: 'close',
  noNavbar: false,
})

const { navigateBack } = useRouterUtils()
</script>

<style lang="scss" scoped>
.nav {
  position: relative;

  .button-back {
    z-index: 1;
  }

  .headline {
    position: absolute;
    right: 0;
    left: 0;
  }
}
</style>
